/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { handleApiError, parametersApi } from 'api';

const useDuplicateAsset = (
  setOpenDuplicateAsset,
  assetId,
  parentId,
  getAsset,
  numberOfCopies,
  isOpenDuplicateAsset,
  setChipsLoading
) => {
  const [openSnackBar, setOpenSnackBar] = useState({
    open: false,
    variant: '',
  });
  const [includeDescendants, setIncludeDescendants] = useState(false);
  const [includeParameters, setIncludeParameters] = useState(false);
  const [assetSummary, setAssetSummary] = useState({});
  const [assetSummaryBase, setAssetSummaryBase] = useState({});

  const handleIncludeDescendantChange = event => {
    setIncludeDescendants(event.target.checked);
  };

  const handleIncludeParametersChange = event => {
    setIncludeParameters(event.target.checked);
  };

  const getAssetSummary = async () => {
    const fetchAssetSummary = async () => {
      const query = {
        asset_id: assetId,
        include_descendants: includeDescendants,
      };

      const response = await parametersApi('getAssetSummary', query).catch(
        error => {
          setChipsLoading(false);
          handleApiError(error, []);
        }
      );
      if (response) {
        const { summary } = response;
        summary.baseAssetCount = summary.assetCount;
        summary.baseParameterCount = summary.parameterCount;
        setChipsLoading(false);
        setAssetSummaryBase(summary);
      }

      return response;
    };
    if (assetId) {
      fetchAssetSummary();
    }
  };
  const updateAssetSummary = () => {
    const updatedAssetSummary = { ...assetSummaryBase };
    updatedAssetSummary.assetCount =
      updatedAssetSummary.baseAssetCount * numberOfCopies;

    if (!includeParameters || numberOfCopies === '') {
      updatedAssetSummary.parameterCount = 0;
    } else {
      updatedAssetSummary.parameterCount =
        updatedAssetSummary.baseParameterCount * numberOfCopies;
    }

    setAssetSummary(updatedAssetSummary);
  };

  useEffect(() => {
    if (isOpenDuplicateAsset) {
      setChipsLoading(true);
      getAssetSummary();
    }
  }, [assetId, isOpenDuplicateAsset, includeDescendants]);

  useEffect(() => {
    if (isOpenDuplicateAsset) {
      updateAssetSummary();
    }
  }, [numberOfCopies, includeParameters, assetSummaryBase]);

  const postDuplicateAsset = async () => {
    const query = {
      asset_id: assetId,
      body: {
        include_descendants: includeDescendants,
        destination_parent_id: parentId,
        include_parameters: includeParameters,
      },
    };

    const response = await parametersApi('postAssetDuplicate', query).catch(
      error => {
        setOpenSnackBar({
          open: true,
          variant: 'error',
          message: error.message,
        });
        handleApiError(error, []);
      }
    );
    if (response) {
      const { duplicatedAssets } = response;
      const didCancel = false;
      await getAsset(duplicatedAssets[0].id, didCancel, true);
      setOpenDuplicateAsset(false);
      setOpenSnackBar({ open: true, variant: 'success' });
    }
    return response;
  };

  return {
    postDuplicateAsset,
    openSnackBar,
    handleIncludeDescendantChange,
    getAssetSummary,
    assetSummary,
    handleIncludeParametersChange,
  };
};

export default useDuplicateAsset;
