/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useImmer } from 'use-immer';
import PT from 'prop-types';
import { isEmpty, uniqBy } from 'lodash';
import { parametersApi } from 'api';
import qs from 'qs';

export const DataSetsContext = React.createContext();

export const DataSetsProvider = ({ children }) => {
  const { projectId, dataTemplateId } = useParams();
  const [searchParams] = useSearchParams({});
  const navigate = useNavigate();
  const [dataSets, setDataSets] = useImmer([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const getDataSets = async source => {
    setLoading(true);
    const query = { sort_by: 'created_at' };
    if (projectId) query.project_id = projectId;
    if (dataTemplateId) query.template_id = dataTemplateId;
    try {
      const response = await parametersApi('getDataSets', query, source.token);

      if (response) {
        const { dataSets: dataSetInstances } = response;
        setDataSets(currentDataSets =>
          uniqBy([...currentDataSets, ...dataSetInstances], 'id')
        );
      }
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 503)
        navigate('/error', {
          replace: true,
          state: {
            status: err.response.status,
            ...err.response.data,
          },
        });
      else {
        setLoading(false);
        setError(err.response?.data);
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (
      (isEmpty(dataSets) && dataTemplateId) ||
      dataTemplateId ||
      (searchParams && dataTemplateId)
    ) {
      setLoading(true);
      setDataSets([]);
      getDataSets(source);
    }
    if (dataTemplateId === undefined) setLoading(false);

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplateId]);

  return (
    <DataSetsContext.Provider
      value={{
        dataSets,
        setDataSets,
        loading,
        error,
        getDataSets,
      }}
    >
      {children}
    </DataSetsContext.Provider>
  );
};

DataSetsProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
