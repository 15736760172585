import config from '../../config';

export const links = [
  {
    name: 'Viva Engage',
    href: config.url.yammerGroup,
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    name: 'DDB Sharepoint site',
    href: config.url.globalAutoSharepoint,
    text: 'gaSharepoint',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    name: 'Github',
    href: config.url.repoFrontend,
    target: '_blank',
    rel: 'noreferrer',
  },
];

export const resources = [
  {
    text: 'Documentation',
    href: config.url.docs,
    name: 'documentation',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    text: 'Integrations',
    href: config.url.docsConnectors,
    name: 'integrations',
    target: '_blank',
    rel: 'noreferrer',
  },
];

export const legal = [
  {
    text: 'Acceptable Use Policy',
    href: config.url.policiesAUP,
    name: 'polices-termsAndConditions',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    text: 'Privacy Policy',
    href: config.url.policiesPrivacy,
    name: 'polices-privacyPolicy',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    text: 'Cookie Policy',
    href: config.url.policiesCookies,
    name: 'polices-cookiesPolicy',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    text: 'Arup Modern Slavery Policy',
    href: config.url.policiesModernSlavery,
    name: 'modern-slavery-policy',
    target: '_blank',
    rel: 'noreferrer',
  },
];
