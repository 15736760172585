/* eslint-disable max-depth */
/* eslint-disable import/prefer-default-export */
import { isEmpty } from 'lodash';

export const formatAssetNavigationDataSetWiz = (
  allAssets,
  parent,
  parentType,
  topAsset,
  parentPlaceholderId,
  assetTypesWithParentAndSubTypes
) => {
  const assetTypes = [];
  const response = [];

  if (allAssets.length) {
    allAssets.reduce((acc, asset) => {
      const parentAssetPlaceholderId = asset?.isTypeOnly
        ? asset.parentAssetPlaceholderId
        : asset?.typeIndex?.parentAssetPlaceholderId;

      if (
        asset.parent === parent ||
        (topAsset && asset.assetType?.id === parentType) ||
        asset?.parent === parentPlaceholderId
      ) {
        const assetTypeId = asset.assetType?.id;

        const existingAssetTypeIndex = assetTypes.indexOf(assetTypeId);

        if (existingAssetTypeIndex === -1) {
          let assetSubType = [];
          if (
            assetTypesWithParentAndSubTypes[assetTypeId] &&
            assetTypesWithParentAndSubTypes[assetTypeId][
              parentAssetPlaceholderId
            ]
          ) {
            assetSubType =
              assetTypesWithParentAndSubTypes[assetTypeId][
                parentAssetPlaceholderId
              ].assetSubTypes;
          }
          assetTypes.push(asset.assetType?.id);
          response.push({
            id: `${asset.assetType?.id}_${parent}`,
            name: asset.assetType?.name,
            assetType: {
              ...asset?.assetType,
              parent: asset?.assetType?.parent || parentType,
            },
            parent,
            parentAssetPlaceholderId,
            setInfo: asset.isTypeOnly
              ? [{ ...asset }]
              : [{ ...asset?.typeIndex }] || [],
            assetSubType,
            isAssetInstance: false,
            children: [],
            isTypeOnly: true,
            hasChildren: !isEmpty(asset.children),
          });
        } else {
          // Asset type exists, update the setInfo array with new subtype
          const existingAssetType = response[existingAssetTypeIndex];
          const existingSetInfoIndex = existingAssetType.setInfo.findIndex(
            info => info.assetSubType?.id === asset?.assetSubType?.id
          );
          if (
            assetTypesWithParentAndSubTypes[assetTypeId] &&
            assetTypesWithParentAndSubTypes[assetTypeId][
              parentAssetPlaceholderId
            ]
          ) {
            if (existingSetInfoIndex === -1 && asset?.assetSubType) {
              // Subtype does not exist and is not null, add it to the setInfo array
              existingAssetType.setInfo.push({
                ...asset?.typeIndex,
                ...asset,
              });
            }
          }
        }
        const index = assetTypes.indexOf(asset.assetType?.id);
        if (!asset.isTypeOnly) {
          let multipleSubtypes = false;

          const subTypesArray =
            assetTypesWithParentAndSubTypes[asset.assetType.id][
              asset?.typeIndex?.parentAssetPlaceholderId
            ]?.assetSubTypes;

          if (!isEmpty(subTypesArray) && subTypesArray.length > 1) {
            multipleSubtypes = true;
          }
          response[index].children.push({
            id: asset?.id,
            hasChildren: !isEmpty(asset.children),
            parent: asset.parent,
            name: asset?.name,
            isAssetInstance: true,
            assetType: { ...asset?.assetType },
            assetSubType: asset?.assetSubType
              ? { ...asset.assetSubType }
              : null,
            edited: asset?.edited,
            new: asset?.new,
            assetTypeMaxCount: asset?.typeIndex?.maxCount,
            assetPlaceholderId: asset?.typeIndex?.id,
            parentAssetPlaceholderId:
              asset?.typeIndex?.parentAssetPlaceholderId,
            multipleSubtypes,
            children: formatAssetNavigationDataSetWiz(
              allAssets,
              asset?.id,
              asset?.assetType?.id,
              null,
              asset?.typeIndex?.id,
              assetTypesWithParentAndSubTypes
            ),
          });
        }
      }
      return acc;
    }, []);
  }
  return response;
};
