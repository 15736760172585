/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import PT from 'prop-types';
import { useContext } from 'react';
import { Typography, Grid } from '@mui/material';
import { ThemeContext } from 'context';
import { StyledTreeItem, StyledBox } from './tree-item-styles';

const TreeItem = props => {
  const {
    textWeight,
    color,
    labelIcon,
    labelInfo,
    labelText,
    onLabelClick,
    node,
    isTemplateDetails,
    ...other
  } = props;
  const { localTheme } = useContext(ThemeContext);

  return (
    <StyledTreeItem
      mode={localTheme}
      aria-label="tree-item"
      data-cy="tree-item"
      label={
        <StyledBox onClick={onLabelClick}>
          {labelIcon && labelIcon}
          {
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid
                item
                container
                alignItems="center"
                xs={12}
                sm={isTemplateDetails ? 12 : 9}
                md={isTemplateDetails ? 12 : 9}
                lg={isTemplateDetails ? 12 : 9}
                xl={isTemplateDetails ? 12 : 9}
              >
                <Grid item>
                  <Typography
                    data-cy="tree-item-label"
                    variant="body2"
                    color={color || 'primary'}
                    component="div"
                    sx={{
                      fontWeight: textWeight,
                      flexGrow: 1,
                    }}
                  >
                    {labelText}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          }

          {labelInfo && (
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          )}
        </StyledBox>
      }
      {...other}
    />
  );
};

TreeItem.propTypes = {
  textWeight: PT.number,
  color: PT.oneOfType([PT.string, PT.func]),
  labelIcon: PT.any,
  labelInfo: PT.oneOfType([PT.string, PT.func]),
  labelText: PT.oneOfType([PT.string, PT.element]).isRequired,
  onLabelClick: PT.func,
  node: PT.oneOfType([
    PT.shape({ edited: PT.bool, id: PT.string, new: PT.bool }),
    PT.func,
  ]),
  isTemplateDetails: PT.bool,
};

TreeItem.defaultProps = {
  textWeight: 400,
  color: 'primary',
  labelIcon: null,
  labelInfo: '',
  onLabelClick: () => {},
  node: {},
  isTemplateDetails: false,
};

export default TreeItem;
