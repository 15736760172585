import { Typography, Chip } from '@mui/material';
import { DataGrid } from 'components/tables';
import { capitalize } from 'lodash';
import {
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import PT from 'prop-types';
import useDataSetParametersList from 'routes/templates/data-set-wizard/data-set-parameters-list/hooks/use-data-set-parameters-list';

const DataSetParametersList = ({
  formattedAssets,
  templateParameters,
  selectedAssets,
}) => {
  const apiRef = useGridApiRef();
  const { dataSetParameters, allDataLoading } = useDataSetParametersList(
    formattedAssets,
    templateParameters,
    selectedAssets
  );

  const columns = [
    {
      field: 'assetInstanceKey',
      headerName: 'Asset instance',
      width: 200,
      renderCell: params => {
        const rowsIds = apiRef.current.getRowGroupChildren({
          groupId: params.id,
        });
        const rowsInfo = rowsIds.map(rowId => apiRef.current.getRow(rowId));
        const isNew = rowsInfo.every(row => row.isAssetInstanceNew);
        return (
          <>
            {isNew && (
              <Chip
                aria-label="new-asset-chip"
                size="small"
                color="secondary"
                label="NEW"
                sx={{
                  marginRight: 1,
                  mt: 0.5,
                  mb: 0.5,
                  maxHeight: theme => theme.spacing(2.5),
                  maxWidth: 50,
                  fontSize: '8px',
                }}
              />
            )}
            <>
              {capitalize(
                rowsInfo[0]?.assetInstanceName || params.row.assetInstanceName
              )}
            </>
          </>
        );
      },
    },
    {
      field: 'assetType',
      headerName: 'Asset type',
      valueGetter: ({ value }) => capitalize(value),
      width: 200,
    },
    {
      field: 'parameterTypeName',
      headerName: 'Parameter',
      width: 300,
      renderCell: ({ value, row }) => {
        return (
          <>
            {row.isNew && (
              <Chip
                aria-label="new-parameter-chip"
                size="small"
                color="secondary"
                label="NEW"
                sx={{
                  marginRight: 1,
                  mt: 0.5,
                  mb: 0.5,
                  maxHeight: theme => theme.spacing(2.5),
                  maxWidth: 50,
                  fontSize: '8px',
                }}
              />
            )}
            <>{capitalize(value)}</>
          </>
        );
      },
      groupable: false,
    },
    {
      field: 'directionId',
      headerName: 'Input/output',
      width: 100,
      valueGetter: ({ value }) => {
        if (value === 'c2eba7d0-c3e8-4270-9fae-8a2064072b4f') return 'Input';
        if (value === '5768cb23-4de0-4760-9a5d-00e04a14dc26') return 'Output';
        return null;
      },
      groupable: false,
    },
  ];

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['assetInstanceKey'],
      },
    },
  });

  return (
    <div>
      <Typography aria-label="parameters-to-add-intro" variant="body2">
        The parameters and assets in the table below will be associated with the
        data set. Those marked as “New” will be created. Assets and parameters
        can be associated with multiple data sets.
      </Typography>
      <DataGrid
        aria-label="data-set-parameters-table"
        height="650px"
        columns={columns}
        rows={dataSetParameters}
        apiRef={apiRef}
        initialState={initialState}
        loading={allDataLoading}
        columnBuffer={4}
        rowBuffer={6}
        defaultGroupingExpansionDepth={-1}
      />
    </div>
  );
};

DataSetParametersList.propTypes = {
  formattedAssets: PT.shape({
    assetTypeId: PT.string,
    children: PT.arrayOf(PT.shape({})),
  }),
  templateParameters: PT.arrayOf(PT.shape({})),
  selectedAssets: PT.arrayOf(PT.shape({})),
};

DataSetParametersList.defaultProps = {
  formattedAssets: {},
  templateParameters: [],
  selectedAssets: [],
};

export default DataSetParametersList;
