import PT from 'prop-types';
import { Email as EmailIcon } from '@mui/icons-material';
import { MenuItem, Typography, Button, useMediaQuery } from '@mui/material';
import { StyledIconButton } from '../../../buttons';

function Contact({ title, handleClose }) {
  const isSmallScreen = useMediaQuery('(max-width:1100px)');

  return (
    <>
      {title && (
        <MenuItem
          component="a"
          color="inherit"
          data-cy="contact"
          onClick={() => {
            window.open('mailto:ddb@arup.com', '_blank');
            handleClose();
          }}
          aria-label="contact"
        >
          <EmailIcon data-testid="contact" />
          <Typography
            variant="button"
            sx={{ paddingLeft: theme => theme.spacing(1) }}
          >
            {title}
          </Typography>
        </MenuItem>
      )}
      {!title && isSmallScreen && (
        <StyledIconButton
          title="contact"
          data-cy="contact"
          component="a"
          onClick={() => window.open('mailto:ddb@arup.com', '_blank')}
          icon={<EmailIcon data-testid="contact" />}
        />
      )}
      {!title && !isSmallScreen && (
        <Button
          title="contact"
          component="a"
          onClick={() => window.open('mailto:ddb@arup.com', '_blank')}
          aria-label="contact"
          data-cy="contact"
          startIcon={<EmailIcon data-testid="contact" />}
        >
          CONTACT
        </Button>
      )}
    </>
  );
}

Contact.propTypes = {
  title: PT.string,
  handleClose: PT.func,
};

Contact.defaultProps = {
  title: null,
  handleClose: null,
};

export default Contact;
