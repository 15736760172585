import { AssetContext, DataSetsContext, DataTemplatesContext } from 'context';
import { useContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import useSummary from 'routes/templates/template-details/asset-types/hooks/use-summary';
import {
  formatAssetNavigationDataSetWiz,
  processAssetPlaceholders,
} from 'utils';
import CreateDataSet from '../create-data-set';
import AssetsTreePanel from '../assets-tree-panel';
import Instructions from '../instructions';
import DataSetParametersList from '../data-set-parameters-list';

export default toggleOpen => {
  const { dataTemplate, templateAssets, assetTypesWithParentAndSubTypes } =
    useContext(DataTemplatesContext);
  const { allAssets, getAssets, assetsLoading } = useContext(AssetContext);
  const { setDataSets } = useContext(DataSetsContext);
  const { formattedAssets, templateParameters } = useSummary();
  const [assetNodes, setAssetNodes] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [suggestedName, setSuggestedName] = useState('');
  const [expanded, setExpanded] = useState([]);

  const getAssetsForTemplate = async () => {
    const templateAssetTypes = [];
    const assetTypesForTemplate = templateAssets.reduce((acc, curr) => {
      if (curr.assetType) {
        templateAssetTypes.push(curr);
        acc.push(curr.assetType.id);
      }
      return acc;
    }, []);
    setAssetTypes([...templateAssetTypes]);
    await getAssets({
      asset_type_id: [...assetTypesForTemplate],
      show_deleted: false,
    });
  };

  useEffect(() => {
    getAssetsForTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedAssets)) {
      const keyTemplateAsset = templateAssets.find(
        templateAsset => templateAsset.isKey
      );
      let keySelectedAsset = {};
      if (!isEmpty(keyTemplateAsset.assetType)) {
        keySelectedAsset = selectedAssets.find(
          selectedAsset =>
            selectedAsset.assetType.id === keyTemplateAsset.assetType.id
        );
      } else {
        keySelectedAsset.name = 'Project level';
      }
      setSuggestedName(`${dataTemplate.name} - ${keySelectedAsset?.name}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssets, templateAssets, dataTemplate]);

  const handleClose = () => {
    toggleOpen();
  };

  const submitDataSet = values => {
    const { dataSet } = values;
    setDataSets(curr => [...dataSet, ...curr]);
    toggleOpen();
  };

  useEffect(() => {
    // unused code commented out for now to test with always expanding only 1 level of depth

    // const templateLevels = templateAssets.filter(
    //   templateAsset => !isEmpty(templateAsset.assetType)
    // ).length;

    const depthCounter = 1;

    // if (templateLevels >= 5) depthCounter = 3;
    // else depthCounter = 2;

    const createInitialExpandedNodes = (nodes, counter = depthCounter) => {
      if (counter < 1) return [];
      const expandedNodes = [];
      expandedNodes.push(nodes?.id);
      if (!isEmpty(nodes?.children)) {
        nodes.children.forEach(child => {
          expandedNodes.push(...createInitialExpandedNodes(child, counter - 1));
        });
      }
      return expandedNodes;
    };
    const initialExpandedNodes = createInitialExpandedNodes(assetNodes[0]);
    setExpanded(curr => [...curr, ...initialExpandedNodes]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetNodes]);

  useEffect(() => {
    if ((allAssets.length || !assetsLoading) && assetTypes.length) {
      let projectAssetsMatchingTemplateAssetTypeIds = [];

      const topAsset = templateAssets.find(
        asset => !asset.parentAssetPlaceholderId
      );

      if (!isEmpty(allAssets)) {
        const phDict = Object.fromEntries(
          templateAssets.map(a => [a.id, { possibleAssets: [] }])
        );
        const root = templateAssets.find(a => !a.parentAssetPlaceholderId);

        processAssetPlaceholders(phDict, allAssets, templateAssets, root);

        const possibleAssetInstances = templateAssets.flatMap(
          a => phDict[a.id].possibleAssets
        );

        // check if all asset children are in the list of possible assets
        // if not set the children on the asset to an empty array
        const possibleAssetIds = possibleAssetInstances.map(a => a.id);
        const allAssetInstances = possibleAssetInstances.map(
          possibleInstance => {
            const hasAllChildren = possibleInstance.children.every(child =>
              possibleAssetIds.includes(child)
            );

            if (hasAllChildren) {
              return possibleInstance;
            }
            return {
              ...possibleInstance,
              children: [],
            };
          }
        );

        projectAssetsMatchingTemplateAssetTypeIds = allAssetInstances;
      }

      const assetTypesWithInfo = assetTypes.reduce((acc, curr) => {
        const parent = assetTypes.find(
          asset => asset.id === curr.parentAssetPlaceholderId
        );
        acc.push({
          ...curr,
          assetType: curr.assetType,
          assetSubType: curr.assetSubType,
          parent: parent?.id || null,
          isTypeOnly: true,
        });
        return acc;
      }, []);

      const nodes = formatAssetNavigationDataSetWiz(
        [...projectAssetsMatchingTemplateAssetTypeIds, ...assetTypesWithInfo],
        null,
        topAsset?.assetType?.id ? topAsset.assetType.id : null,
        !!topAsset?.assetType?.id,
        null,
        assetTypesWithParentAndSubTypes
      );
      setAssetNodes(nodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAssets, assetTypes, assetsLoading]);

  const steps = [
    {
      label: 'Instructions',
      optional: false,
      header: ' Read these instructions before creating a data set',
      content: () => <Instructions />,
    },
    {
      label: 'Select required assets',
      optional: false,
      header: '',
      content: props => (
        <AssetsTreePanel
          assetNodes={assetNodes}
          formattedAssets={formattedAssets}
          selectedAssets={selectedAssets}
          setSelectedAssets={setSelectedAssets}
          expanded={expanded}
          setExpanded={setExpanded}
          {...props}
        />
      ),
    },
    {
      label: 'Confirm parameters to add',
      optional: false,
      header: 'Review parameters',
      content: props => (
        <DataSetParametersList
          formattedAssets={formattedAssets}
          templateParameters={templateParameters}
          selectedAssets={selectedAssets}
          {...props}
        />
      ),
    },
    {
      label: 'Create data set',
      optional: false,
      header: 'Create data set',
      content: props => (
        <CreateDataSet selectedAssets={selectedAssets} {...props} />
      ),
    },
  ];

  return {
    selectedAssets,
    setSelectedAssets,
    steps,
    handleClose,
    submitDataSet,
    suggestedName,
    expanded,
    setExpanded,
    assetNodes,
    assetTypes,
  };
};
