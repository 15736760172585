/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-confusing-arrow */
import { AssetNavigationTreeItem } from 'components/tree/tree-item';
import PT, { string } from 'prop-types';
import { isEmpty } from 'lodash';
import RemovedAssetIcon from 'components/Icons/removed-asset';
import { ChevronRight, ExpandMore, MoreVert } from '@mui/icons-material';
import {
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  Dialog,
  DialogContent,
  ClickAwayListener,
} from '@mui/material';
import {
  useSearchParams,
  useLocation,
  createSearchParams,
  useNavigate,
} from 'react-router-dom';
import qs from 'qs';
import { useContext, useState } from 'react';
import { AssetContext, ThemeContext } from 'context';
import useHeaders from 'routes/parameters-page/asset-details-pane/header/hooks';
import WarningDialog from 'components/dialogs/warning';
import useButton from 'components/buttons/hooks/use-button';
import config from 'config';
import StyledTreeIconButton from './tree-items-styles';
import DuplicateAsset from '../../duplicate-asset';

const TreeItems = ({
  node,
  expanded,
  isHierarchy,
  setExpanded,
  selectedNode,
}) => {
  const navigate = useNavigate();
  const { handleDeleteClick, displayWarning } = useHeaders(node, () => {});
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const { ...parsedSearch } = qs.parse(searchParams.toString());
  const searchString = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };
  const {
    theAsset,
    getAssets,
    assetsLoading,
    allAssets,
    isExpanding,
    getAsset,
  } = useContext(AssetContext);
  const showDeleted = parsedSearch.show_deleted_assets === 'true';
  delete searchString.project_parameter;
  const { localTheme } = useContext(ThemeContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isDuplicateAssetOpen, setIsDuplicateAssetOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = event => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    const menuHeight = 124;
    let top = rect.bottom - 16;
    if (top + menuHeight > viewportHeight) {
      top = rect.bottom - menuHeight - 8;
    }
    setMenuPosition({ top, left: rect.right });
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleDelete = () => {
    handleMenuClose();
    handleDeleteClick();
  };

  const handleDuplicateAssetClick = event => {
    event.stopPropagation();
    handleMenuClose();
    setIsDuplicateAssetOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  const { renderTooltipDeleteIcon, renderTooltipDuplicateButton } = useButton();

  const onOpenClick = () => {
    if (!isHierarchy) return;
    if (!node.id.includes('_')) {
      if (node.id !== theAsset.id) {
        const selected = allAssets.find(asset => asset.id === node.id);
        const chunkSize = 99;
        // eslint-disable-next-line max-depth
        Array.from(
          { length: Math.ceil(selected.children.length / chunkSize) },
          (_, i) =>
            getAssets({
              asset_id: selected.children.slice(
                i * chunkSize,
                i * chunkSize + chunkSize
              ),
            })
        );
      }
    }
    setExpanded(curr => [...curr, node.id]);
  };

  const handleCopy = event => {
    event.stopPropagation();
    navigator.clipboard.writeText(node.id);
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  };

  const onNodeToggle = () => {
    if (!isHierarchy) return;
    if (!node.id.includes('_')) {
      navigate({
        pathname: `asset/${node.id}/parameters`,
        search: `?${createSearchParams({
          ...searchString,
        })}`,
      });
    }
    handleMenuClose();
  };

  const menuItems = [
    {
      label: 'Duplicate Asset',
      action: 'duplicateAsset',
      disabled:
        !isEmpty(node.assetSubType) || renderTooltipDuplicateButton()[1],
      tooltipTitle: !isEmpty(node.assetSubType)
        ? 'Not available for Asset sub type instances'
        : renderTooltipDuplicateButton()[0],
      onClick: handleDuplicateAssetClick,
    },
    { label: 'Copy ID', action: 'copyId', onClick: handleCopy },
    {
      label: 'Delete Asset',
      action: 'deleteAsset',
      onClick: handleDelete,
      disabled: renderTooltipDeleteIcon()[1],
      tooltipTitle: renderTooltipDeleteIcon()[0],
    },
  ];
  const labelInfo = showDeleted
    ? node.children?.length
    : node.children?.filter(asset => asset?.deletedAt === null).length;
  const deletedStyleColor = theme =>
    showDeleted && node?.deletedAt
      ? theme.palette.secondary.dark
      : theme.palette.primary.main;

  const deletedChildren = node?.children?.every(type => {
    if (!isEmpty(type.children)) {
      return !type?.children?.every(child => {
        return child?.deletedAt !== null;
      });
    }
    return true;
  });

  return (
    (!isHierarchy ||
      showDeleted ||
      node?.isAssetInstance ||
      node.isTypeOnly ||
      (!showDeleted &&
        !node?.children?.every(child => child.deletedAt !== null))) && (
      <>
        <AssetNavigationTreeItem
          key={node?.id}
          nodeId={node?.id}
          node={node}
          labelText={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {node?.name}
              {node.isAssetInstance &&
                !node.deletedAt &&
                config?.featureFlags?.isDuplicateAssetsEnabled && (
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    className="more-vert-icon"
                    id="more-vert-icon"
                  >
                    <MoreVert
                      className="more-vert-icon"
                      onClick={handleMenuOpen}
                      style={{ cursor: 'pointer' }}
                    />
                    <Menu
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={handleMenuClose}
                      style={{
                        position: 'absolute',
                        height: '300px',
                        top: menuPosition?.top,
                        left: menuPosition?.left,
                      }}
                    >
                      {menuItems.map(item =>
                        item.action === 'copyId' ? (
                          <ClickAwayListener
                            key={item.action}
                            onClickAway={handleTooltipClose}
                          >
                            <Tooltip
                              title="Copied"
                              open={tooltipOpen}
                              onClick={handleTooltipClose}
                            >
                              <MenuItem onClick={item.onClick}>
                                {item.label}
                              </MenuItem>
                            </Tooltip>
                          </ClickAwayListener>
                        ) : (
                          <Tooltip key={item.action} title={item.tooltipTitle}>
                            <span>
                              <MenuItem
                                onClick={item.onClick}
                                disabled={item.disabled}
                              >
                                {item.label}
                              </MenuItem>
                            </span>
                          </Tooltip>
                        )
                      )}
                    </Menu>
                  </div>
                )}
            </div>
          }
          classes={{
            label: node.isAssetInstance ? 'instanceLabel' : 'typeLabel',
            iconContainer: 'assetNavigationTree',
          }}
          labelInfo={
            node?.children?.length > 0 && !node?.isAssetInstance
              ? `(${labelInfo})`
              : ''
          }
          labelIcon={
            showDeleted &&
            node.deletedAt && (
              <RemovedAssetIcon
                sx={{
                  paddingRight: theme => theme.spacing(0.5),
                  color: theme => theme.palette.secondary.dark,
                }}
              />
            )
          }
          onLabelClick={onNodeToggle}
          icon={
            <Grid container>
              <Grid item xs={12}>
                {(assetsLoading &&
                  node.hasChildren &&
                  isEmpty(node.children) &&
                  expanded.includes(node.id)) ||
                (assetsLoading &&
                  (isExpanding || selectedNode === node.id) &&
                  node.id === theAsset.id) ? (
                  <CircularProgress size="50%" aria-label="loading-spinner" />
                ) : !showDeleted && node.isAssetInstance && !deletedChildren ? (
                  <div aria-label="deleted-children" />
                ) : (node.hasChildren || !isEmpty(node.children)) &&
                  !expanded.includes(node.id) ? (
                  <StyledTreeIconButton
                    sx={{ padding: 1.25 }}
                    mode={localTheme}
                    title={`Expand ${node.name}`}
                    data-cy="tree-expand-icon"
                    onClick={onOpenClick}
                    icon={<ChevronRight />}
                  />
                ) : (
                  (node.hasChildren || !isEmpty(node.children)) &&
                  expanded.includes(node.id) && (
                    <StyledTreeIconButton
                      sx={{ padding: 1.25 }}
                      mode={localTheme}
                      data-cy="tree-collapse-icon"
                      title={`Collapse ${node.name}`}
                      onClick={() => {
                        setExpanded(curr =>
                          curr.filter(nodes => nodes !== node.id)
                        );
                      }}
                      icon={<ExpandMore />}
                    />
                  )
                )}
              </Grid>
            </Grid>
          }
          color={theme =>
            node?.isAssetInstance
              ? deletedStyleColor(theme)
              : theme.palette.text
          }
          textWeight={node?.isAssetInstance ? 400 : 700}
        >
          {!isEmpty(node.children)
            ? node.children
                .filter(deletedAsset =>
                  showDeleted ? deletedAsset : deletedAsset.deletedAt === null
                )
                .map(child => {
                  if (Array.isArray(child)) {
                    return child.reduce((allChildren, grandChild) => {
                      if (!showDeleted && grandChild?.deletedAt === null) {
                        return (
                          <TreeItems
                            isHierarchy={isHierarchy}
                            node={grandChild}
                            key={grandChild.id}
                            expanded={expanded}
                            setExpanded={setExpanded}
                            selectedNode={selectedNode}
                          />
                        );
                      }
                      return allChildren;
                    }, {});
                  }
                  return (
                    <TreeItems
                      isHierarchy={isHierarchy}
                      node={child}
                      key={child.id}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      selectedNode={selectedNode}
                    />
                  );
                })
            : null}
        </AssetNavigationTreeItem>
        <Dialog
          open={isDuplicateAssetOpen}
          onClose={() => setIsDuplicateAssetOpen(false)}
        >
          <DialogContent>
            <DuplicateAsset
              setOpenDuplicateAsset={setIsDuplicateAssetOpen}
              assetId={node.id}
              parentId={node.parent}
              getAsset={getAsset}
              isDuplicateAssetOpen={isDuplicateAssetOpen}
            />
          </DialogContent>
        </Dialog>
        <WarningDialog
          open={displayWarning?.open}
          handleAction={displayWarning.handleAccept}
          handleClose={displayWarning.handleClose}
          title={displayWarning?.title}
          body={displayWarning.body}
          secondaryButtonText={displayWarning?.secondaryButtonText}
          primaryButtonText={displayWarning?.primaryButtonText}
        />
      </>
    )
  );
};
TreeItems.propTypes = {
  node: PT.shape({
    assetSubType: PT.shape({}),
    id: PT.string,
    name: PT.string,
    isAssetInstance: PT.bool,
    children: PT.array,
    deletedAt: PT.string,
    hasChildren: PT.bool,
    checked: PT.bool,
    parent: PT.string,
    parentChecked: PT.bool,
    isTypeOnly: PT.bool,
    setInfo: PT.arrayOf(PT.shape({ maxCount: PT.number })),
    assetTypeCount: PT.number || PT.null,
    multipleSubtypes: PT.bool,
  }),

  expanded: PT.arrayOf(string),
  isHierarchy: PT.bool.isRequired,
  setExpanded: PT.func.isRequired,

  selectedNode: PT.string,
};
TreeItems.defaultProps = {
  node: {},
  expanded: [],
  selectedNode: '',
};
export default TreeItems;
