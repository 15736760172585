import { filterAssetsByType, filterAssetsByParent } from './index';

const processAssetPlaceholders = (phDict, allAssetsArr, aphs, currentPh) => {
  const filteredByType = filterAssetsByType(allAssetsArr, currentPh);
  const filteredByParent = filterAssetsByParent(
    phDict,
    filteredByType,
    currentPh
  );
  // eslint-disable-next-line no-param-reassign
  phDict[currentPh.id].possibleAssets = filteredByParent.map(instance => {
    return {
      ...instance,
      typeIndex: currentPh,
    };
  });

  const aphChildren = aphs.filter(
    a => a.parentAssetPlaceholderId === currentPh.id
  );
  aphChildren.forEach(child =>
    processAssetPlaceholders(phDict, allAssetsArr, aphs, child)
  );
};

export default processAssetPlaceholders;
