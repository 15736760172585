/* eslint-disable no-nested-ternary */
import {
  Collapse,
  Box,
  Skeleton,
  Typography,
  Grid,
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { TreeView } from '@mui/lab';
import Alert from 'components/alerts';
import { ChevronRight, ExpandMore, Circle } from '@mui/icons-material';
import ExpandOrCollapseButton from 'components/buttons/icons/expand-or-collapse';
import { capitalize, isEmpty } from 'lodash';
import { StyledIconButton } from 'components/buttons';
import { StyledTreeItem } from 'components/tree/tree-item/tree-item-styles';
import useSummary from '../hooks/use-summary';

function AssetTypesSummary() {
  const {
    openSummary,
    handleOpen,
    loadingAssets,
    formattedAssets,
    loadingParameters,
    expandedNodes,
    error,
  } = useSummary();

  const summaryText = [
    { key: 'A new ', fontWeight: 'regular' },
    {
      key: 'data set ',
      fontWeight: 'bold',
    },
    {
      key: 'will create parameters using these ',
      fontWeight: 'regular',
    },
    {
      key: 'asset type instances:',
      fontWeight: 'bold',
    },
  ];

  const renderTree = nodes => {
    return (
      <StyledTreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={
          <>
            <Grid container spacing={0.5}>
              <Grid item>
                <Typography
                  variant="body2"
                  color="primary"
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    flexGrow: 1,
                  }}
                >
                  {nodes.assetType?.name
                    ? capitalize(nodes.assetType.name)
                    : capitalize(nodes.name)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  color="primary"
                  variant={nodes.maxCount ? 'body2' : 'body1'}
                  fontWeight="bold"
                  aria-label="asset-type-name-tree"
                >
                  {nodes.maxCount ? `(${nodes.maxCount})` : `(${'∞'})`}
                </Typography>
              </Grid>
            </Grid>
            <List
              aria-label="asset-level-info"
              key={nodes.id}
              role="list"
              sx={{ pt: 0 }}
            >
              <ListItem
                sx={{
                  pt: 0,
                  pb: 0,
                  pl: 1,
                  pr: 0,
                  textTransform: 'none',
                }}
                key={nodes.id}
              >
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'body2',
                    color: 'textSecondary',
                  }}
                  secondaryTypographyProps={{
                    variant: 'body2',
                    color: 'textSecondary',
                  }}
                  primary={
                    <>
                      <Typography
                        variant="body2"
                        component="span"
                        fontWeight="medium"
                        color="textPrimary"
                        aria-label="asset-placeholder-name"
                      >
                        {`${'Name'}: `}
                      </Typography>
                      {nodes.assetSubType?.name
                        ? nodes.assetSubType.name
                        : nodes.name}
                    </>
                  }
                  secondary={
                    <>
                      <Typography
                        variant="body2"
                        component="span"
                        fontWeight="medium"
                        color="textPrimary"
                        aria-label="asset-placeholder-description"
                      >
                        {'Description: '}
                      </Typography>
                      {nodes.description}
                    </>
                  }
                />
              </ListItem>
            </List>
          </>
        }
      >
        {!isEmpty(nodes.children)
          ? nodes.children.map(node => renderTree(node))
          : null}
      </StyledTreeItem>
    );
  };

  const renderAssetsWithParameters = asset => {
    if (!isEmpty(asset.parameters)) {
      return (
        <div key={asset.id}>
          <Typography
            variant="body2"
            aria-label="asset-type-name-parameters-list"
            sx={{
              marginLeft: theme => theme.spacing(1.5),
              textTransform: 'capitalize',
            }}
          >{`${asset.assetType?.name ? asset.assetType.name : asset.name} (${
            asset.name
          }):`}</Typography>
          <List aria-label="parameters-list" key={asset.id} role="list">
            {asset.parameters.map(parameter => (
              <ListItem
                sx={{ pt: 0, pb: 0, pl: theme => theme.spacing(3) }}
                aria-label="parameter-name"
                key={parameter.id}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '16px',
                  }}
                >
                  <Circle
                    sx={{
                      fontSize: '6px',
                      // eslint-disable-next-line no-confusing-arrow
                      color: theme =>
                        theme.palette.mode === 'dark'
                          ? theme.palette.common.white
                          : theme.palette.common.black,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={parameter.parameterTypeName}
                />
              </ListItem>
            ))}
          </List>
          {!isEmpty(asset.children) &&
            asset.children.map(renderAssetsWithParameters)}
        </div>
      );
    }

    return (
      !isEmpty(asset.children) && asset.children.map(renderAssetsWithParameters)
    );
  };

  return (
    <Box aria-label="contentBox" width="auto">
      {!isEmpty(error) ? (
        <Box sx={{ padding: theme => theme.spacing(3) }}>
          <Alert
            ariaLabel="templateAssetAlert"
            title={error?.msg}
            text={error?.details}
          />
        </Box>
      ) : (
        <>
          <ExpandOrCollapseButton
            buttonSize="small"
            open={openSummary}
            onClick={handleOpen}
            sx={{ marginLeft: theme => theme.spacing(-1.5) }}
          />
          <Typography variant="caption" display="inline" color="textSecondary">
            Summary
          </Typography>
          <Collapse in={openSummary}>
            <>
              {!isEmpty(formattedAssets) &&
                summaryText.map(text => (
                  <Typography
                    key={text.key}
                    variant="body1"
                    display="inline"
                    fontWeight={text.fontWeight}
                  >
                    {text.key}
                  </Typography>
                ))}
              {loadingAssets ? (
                <Skeleton
                  height={30}
                  width="100%"
                  display="inline"
                  aria-label="loading-assets"
                />
              ) : (
                <TreeView
                  aria-label="asset-tree"
                  sx={{
                    paddingTop: theme => theme.spacing(1.25),
                    paddingBottom: theme => theme.spacing(1.25),
                  }}
                  defaultCollapseIcon={
                    <StyledIconButton
                      buttonSize="small"
                      icon={<ExpandMore />}
                    />
                  }
                  defaultExpandIcon={
                    <StyledIconButton
                      buttonSize="small"
                      icon={<ChevronRight />}
                    />
                  }
                  disableSelection
                  defaultExpanded={[...expandedNodes]}
                >
                  {!isEmpty(formattedAssets) &&
                    renderTree(formattedAssets, 'assets')}
                </TreeView>
              )}
              {!isEmpty(formattedAssets) && (
                <Typography
                  variant="body1"
                  display="inline-block"
                  sx={{ marginBottom: theme => theme.spacing(1) }}
                >
                  This template will group the following parameters within the
                  asset instances:
                </Typography>
              )}
              {loadingParameters ? (
                <Skeleton
                  height={30}
                  width="100%"
                  display="inline"
                  aria-label="loading-parameters"
                />
              ) : (
                renderAssetsWithParameters(formattedAssets)
              )}
            </>
          </Collapse>
        </>
      )}
    </Box>
  );
}

export default AssetTypesSummary;
