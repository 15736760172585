import { useState } from 'react';

export default () => {
  const [selectedNode, setSelectedNode] = useState('');

  const onNodeSelect = (event, nodeId) => {
    setSelectedNode(nodeId);
    event.preventDefault();
  };

  const onNodeToggle = event => {
    event.preventDefault();
  };

  return {
    onNodeSelect,
    onNodeToggle,
    selectedNode,
    setSelectedNode,
  };
};
