/* eslint-disable import/prefer-default-export */
import { isEmpty } from 'lodash';

export const formatAssetNavigation = (allAssets, parent) => {
  const assetTypes = [];
  const response = [];
  if (allAssets.length) {
    allAssets.reduce((acc, asset) => {
      if (asset.parent === parent) {
        if (!assetTypes.includes(asset.assetType?.id)) {
          assetTypes.push(asset.assetType?.id);
          response.push({
            id: `${asset.assetType?.id}_${asset?.parent}`,
            name: asset.assetType?.name,
            assetType: {
              ...asset?.assetType,
              parent: asset?.assetType?.parent,
            },
            parent,
            setInfo: asset.isTypeOnly
              ? [{ ...asset }]
              : [{ ...asset?.typeIndex }] || null,
            assetSubType: {
              ...asset?.assetSubType,
            },
            isAssetInstance: false,
            deletedAt: null,
            children: [],
            hasChildren: !isEmpty(asset.children),
          });
        }
        const index = assetTypes.indexOf(asset.assetType?.id);
        response[index].children.push({
          id: asset?.id,
          hasChildren: !isEmpty(asset.children),
          parent: asset.parent,
          name: asset?.name,
          deletedAt: asset?.deletedAt,
          isAssetInstance: true,
          assetSubType: asset?.assetSubType,
          children: !isEmpty(asset.children)
            ? formatAssetNavigation(allAssets, asset?.id)
            : asset.children,
        });
        return asset;
      }
      return acc;
    }, []);
  }
  return response;
};
