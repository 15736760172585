import { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import axios from 'axios';
import qs from 'qs';
import { handleApiError, parametersApi } from 'api';

export default (assetNodes, isProjectAssetHierarchy, expanded, setExpanded) => {
  const { search } = useLocation();
  const { parameterId, assetId } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedNode, setSelectedNode] = useState('');
  const searchString = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };
  const projectParameters = searchString.project_parameter;
  searchString.order = searchString.order || 'asc';
  searchString.sort_by = searchString.sortBy || 'parameter_type_name';
  delete searchString.project_parameter;

  useEffect(() => {
    if (projectParameters) {
      setExpanded([]);
    }
    if (!projectParameters && !assetId && !parameterId) {
      setExpanded([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectParameters, assetId, parameterId]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getHierarchy = async () => {
      const response = await parametersApi(
        'getAssetHierarchy',
        {
          asset_ids: assetId,
          show_deleted: searchParams.get('show_deleted_assets'),
        },
        source.token
      ).catch(err => handleApiError(err, []));

      if (response) {
        const { hierarchies } = response;
        const treePath = hierarchies.flat().reverse();
        const path = [];
        if (!isEmpty(treePath))
          treePath.forEach((parent, i) => {
            if (i === treePath.length - 1)
              path.unshift(parent.id, `${parent.assetTypeId}_null`);
            else
              path.unshift(
                parent.id,
                `${parent.assetTypeId}_${treePath[i + 1].id}`
              );
          });
        setExpanded(path);
      }
    };
    if (assetId && isEmpty(assetNodes) && isProjectAssetHierarchy)
      getHierarchy(); // eslint-disable-line react-hooks/exhaustive-deps

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const onNodeSelect = (event, nodeId) => {
    setSelectedNode(nodeId);
    event.preventDefault();
  };

  const onNodeToggle = event => {
    event.preventDefault();
  };

  return {
    onNodeSelect,
    onNodeToggle,
    expanded,
    setExpanded,
    selectedNode,
    setSelectedNode,
  };
};
