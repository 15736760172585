import { useState, useEffect } from 'react';
import { parametersApi } from 'api';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';

export default (values, pushMutator, selectedAssets) => {
  const { projectId, dataTemplateId } = useParams();
  const [error, setError] = useState({});
  const [isAssetsLoading, setIsAssetsLoading] = useState(false);
  const [isDataSetLoading, setIsDataSetLoading] = useState(false);
  const [newDataSetAssets, setNewDataSetAssets] = useState([]);
  const [newDataSet, setNewDataSet] = useState([]);
  const [dataSetName, setDataSetName] = useState('');
  const [assetsError, setAssetsError] = useState({});
  const [dataSetError, setDataSetError] = useState({});

  useEffect(() => {
    if (values.dataSetName) {
      setDataSetName(values.dataSetName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newAssets = selectedAssets.filter(asset => asset.new);
  const postNewAssets = async () => {
    const newAssetsBody = newAssets.map(newAsset => {
      const body = {
        project_id: projectId,
        asset_id: newAsset.id,
      };
      if (newAsset.parent) {
        body.parent_id = newAsset.parent;
      }
      if (newAsset.assetSubType?.id) {
        body.asset_sub_type_id = newAsset.assetSubType.id;
      } else {
        body.name = newAsset.name;
        body.asset_type_id = newAsset.assetType.id;
      }

      return body;
    });
    if (!isEmpty(newAssetsBody)) {
      try {
        setIsAssetsLoading(true);
        const response = await parametersApi('postAsset', {
          body: { assets: newAssetsBody },
        });
        if (response) {
          const { assets } = response;
          setNewDataSetAssets(assets);
          pushMutator('newAssets', assets[0]);
          setIsAssetsLoading(false);
        }
      } catch (err) {
        setAssetsError(err.response?.data);
        setError(err.response?.data);
        setIsAssetsLoading(false);
      }
    }
  };

  const dataSetAssets = selectedAssets.reduce((acc, asset) => {
    const index = acc.findIndex(
      placeholder =>
        placeholder.asset_placeholder_id === asset.assetPlaceholderId
    );
    if (index === -1) {
      acc.push({
        asset_placeholder_id: asset.assetPlaceholderId,
        asset_ids: [asset.id],
      });
    } else {
      acc[index].asset_ids.push(asset.id);
    }
    return acc;
  }, []);

  const postDataSet = async () => {
    const dataSetBody = {
      id: uuid(),
      name: dataSetName,
      template_id: dataTemplateId,
      project_id: projectId,
      data_set_assets: dataSetAssets,
    };

    try {
      setIsDataSetLoading(true);
      const response = await parametersApi('postDataSet', {
        body: { data_set: dataSetBody },
      });
      if (response) {
        const { dataSets } = response;
        setNewDataSet(dataSets);
        pushMutator('dataSet', dataSets[0]);
        setIsDataSetLoading(false);
      }
    } catch (err) {
      setDataSetError(err.response?.data);
      setError(err.response?.data);
      pushMutator('postDataSetError', err.response?.data);
      setIsDataSetLoading(false);
    }
  };

  const onClick = async () => {
    if (isEmpty(dataSetError)) {
      await postNewAssets();
      if (!isAssetsLoading && isEmpty(assetsError)) {
        await postDataSet();
      }
    } else {
      await postDataSet();
    }
  };

  return {
    onClick,
    isAssetsLoading,
    isDataSetLoading,
    newDataSetAssets,
    newAssets,
    newDataSet,
    error,
    dataSetName,
    setDataSetName,
    setError,
  };
};
