/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import PT from 'prop-types';
import { isEmpty, findIndex, uniqBy, isEqual, orderBy } from 'lodash';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { handleApiError, parametersApi } from 'api';
import { GlobalTypesContext } from '.';
import formatAssetTypeHierarchy from '../utils/format-asset-type-hierarchy';

export const AssetContext = React.createContext();

export const AssetProvider = ({ children }) => {
  const { assetTypeTrees, getAssetTypeTree, setAssetTypeTrees } =
    useContext(GlobalTypesContext);
  const { projectId, assetId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [deleteError, setDeleteError] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [theAsset, setTheAsset] = useState({});
  const [allAssets, setAllAssets] = useImmer([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assetsLoading, setAssetsLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [wizardOpen, setWizardOpen] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [assetWizardColumns, setAssetWizardColumns] = useImmer([]);
  const [assetManipulation, setAssetManipulation] = useImmer({
    isOpen: {},
    isSelected: {},
    isEdited: {},
    isAddingNew: {},
    hasSubTypes: {},
    allSubTypesUsed: {},
  });
  const [allRefs, setAllRefs] = useState({});

  const getAsset = async (id, didCancel, duplicate) => {
    setAssetsLoading(true);
    if (!duplicate) {
      setTheAsset({});
    }
    setIsLoading(true);
    setError(undefined);
    try {
      const response = await parametersApi('getAsset', {
        asset_id: id,
        show_deleted: true,
      });
      if (response && !didCancel) {
        const { asset } = response;
        if (asset.deletedAt) {
          searchParams.set('show_deleted_assets', true);
          searchParams.set('show_deleted_parameters', true);
          setSearchParams(searchParams);
        }
        if (duplicate) {
          setAllAssets(curr => [...curr, asset]);
        }
        setAssetsLoading(false);
        if (!duplicate) {
          setTheAsset(asset);
        }

        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setError(err?.response?.data);
    }
  };

  useEffect(() => {
    const refs = allAssets.reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});
    setAllRefs({ ...refs });
  }, [allAssets]);

  useEffect(() => {
    let didCancel = false;
    const source = axios.CancelToken.source();

    if (assetId) getAsset(assetId, didCancel, false);
    return () => {
      didCancel = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const getAssets = async assetQuery => {
    setAssetsLoading(true);
    const source = axios.CancelToken.source();

    const query = {
      project_id: projectId,
      sort_by: 'name',
      order: 'asc',
      show_deleted: true,
      page_limit: 50,
      ...assetQuery,
    };
    try {
      const response = await parametersApi('getAssets', query, source.token);
      if (response) {
        const { assets: assetsResponse, paging } = response;
        if (!isEmpty(assetsResponse)) {
          setAllAssets(curr => uniqBy([...curr, ...assetsResponse], 'id'));
          // eslint-disable-next-line max-depth
          if (paging?.cursors?.after) {
            await getAssets({ ...assetQuery, after: paging?.cursors?.after });
          }
        }
      }
      setAssetsLoading(false);
    } catch (err) {
      setAssetsLoading(false);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (theAsset.id && !isEmpty(allAssets) && !isEmpty(theAsset.children)) {
      const chunkSize = 99;
      Array.from(
        { length: Math.ceil(theAsset.children.length / chunkSize) },
        (_, i) =>
          getAssets({
            asset_id: theAsset.children.slice(
              i * chunkSize,
              i * chunkSize + chunkSize
            ),
          })
      );
    } else if (theAsset.id && isEmpty(allAssets)) {
      const getHierarchy = async () => {
        const response = await parametersApi(
          'getAssetHierarchy',
          {
            show_deleted: true,
            asset_ids: [theAsset.id],
          },
          source.token
        ).catch(err => handleApiError(err, []));
        if (response) {
          const { hierarchies } = response;
          if (hierarchies) {
            getAssets({
              descendants_of: hierarchies[0][0].id,
            });
          }
        }
      };
      getHierarchy();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theAsset, searchParams]);

  useEffect(() => {
    if (index === 0 && isEmpty(allAssets) && assetTypeTrees?.length) {
      getAssets({ asset_type_id: assetTypeTrees.map(type => type.id) });
    } else if (assetTypeTrees?.length && theAsset.id) {
      const tree = assetTypeTrees.filter(type => {
        return type.id === theAsset.assetType.id;
      });
      if (tree[0]?.children) {
        getAssets({
          asset_type_id: tree[0].children.map(child => {
            return child.id;
          }),
          descendants_of: [theAsset.id],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTypeTrees]);

  useEffect(() => {
    const orderedAssets = orderBy(
      allAssets,
      ['deletedAt', asset => asset.name.toLowerCase()],
      ['desc', 'asc']
    );
    if (!assetsLoading && !isEmpty(assetTypeTrees) && index === 0) {
      const response = formatAssetTypeHierarchy(
        { id: null },
        assetTypeTrees,
        orderedAssets
      );
      setAssetWizardColumns([response]);
    } else if (
      allAssets.length &&
      !assetsLoading &&
      !isEmpty(assetTypeTrees) &&
      index > 0 &&
      !isEqual(
        assetTypeTrees[0]?.children?.map(tree => tree.id),
        assetWizardColumns[assetWizardColumns.length - 1]?.map(col => col.id)
      )
    ) {
      const anotherColumn = formatAssetTypeHierarchy(
        theAsset,
        assetTypeTrees[0].children,
        orderedAssets
      );
      setAssetWizardColumns(curr => {
        if (curr.length > index) {
          const removeLastXColumns = curr.length - index;
          curr.splice(index, removeLastXColumns);
        }
        curr[index] = anotherColumn; // eslint-disable-line no-param-reassign
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsLoading, wizardOpen]);

  const getAnotherColumn = async (assetTypeId, colIndex) => {
    const newIndex = colIndex + 1;
    setAssetWizardColumns(curr => curr.slice(0, newIndex));
    const response = await getAssetTypeTree([assetTypeId]);
    if (response && !isEmpty(response[0].children)) {
      setAssetTypeTrees(response);
    } else {
      setAssetTypeTrees([{ empty: true }]);
      setAssetWizardColumns(curr => curr.slice(0, newIndex));
    }
  };

  const addAssetToWizard = (columnIndex, typeIndex, newAsset) => {
    setAllAssets(curr => {
      let parentIndex = -1;
      parentIndex = findIndex(curr, item => {
        return item.id === newAsset.parent;
      });
      if (parentIndex !== -1) curr[parentIndex].children.unshift(newAsset.id);
      curr.unshift(newAsset);
    });
    setAssetWizardColumns(curr => {
      // eslint-disable-next-line no-param-reassign
      curr.length = columnIndex + 1;
      // eslint-disable-next-line no-param-reassign
      curr[columnIndex][typeIndex].children = [
        newAsset,
        ...curr[columnIndex][typeIndex].children,
      ];
    });
    setTheAsset(newAsset);
    setIndex(columnIndex + 1);
    getAnotherColumn(newAsset.assetType.id, columnIndex);
  };

  const updateAssetInWizard = (
    columnIndex,
    typeIndex,
    assetInstance,
    updatedAsset
  ) => {
    setAllAssets(curr => {
      const indexOfUpdate = findIndex(
        curr,
        existing => existing.id === updatedAsset.id
      );
      curr[indexOfUpdate] = updatedAsset; // eslint-disable-line no-param-reassign
    });
    setAssetWizardColumns(curr => {
      // eslint-disable-next-line no-param-reassign
      curr[columnIndex][typeIndex].children[assetInstance] = updatedAsset;
    });
  };

  const patchAssets = async () => {
    const updating = allAssets.filter(asset => asset?.editedName && !asset.new);
    if (!isEmpty(updating)) {
      const allUpdatedAssets = await Promise.all(
        updating.map(editedAsset => {
          return parametersApi('patchAsset', {
            asset_id: editedAsset.id,
            body: {
              name: editedAsset.name,
            },
          });
        })
      );
      if (allUpdatedAssets) {
        setAllAssets(curr => {
          allUpdatedAssets.forEach(({ asset }) => {
            const indexOfUpdate = findIndex(
              curr,
              existing => existing.id === asset.id
            );
            curr[indexOfUpdate] = asset; // eslint-disable-line no-param-reassign
          });
        });
      }
    }
    return updating;
  };

  const postAssets = async () => {
    const newAssets = allAssets.filter(asset => asset?.new);
    const newAssetsBody = newAssets.map(newAsset => {
      const body = {
        project_id: projectId,
        asset_id: newAsset.id,
      };
      if (newAsset.parent) {
        body.parent_id = newAsset.parent;
      }
      if (newAsset.assetSubType) {
        body.asset_sub_type_id = newAsset.assetSubType.id;
      } else {
        body.name = newAsset.name;
        body.asset_type_id = newAsset.assetType.id;
      }
      return body;
    });
    if (!isEmpty(newAssetsBody)) {
      const response = await parametersApi('postAsset', {
        body: { assets: newAssetsBody },
      });
      if (response) {
        const { assets } = response;
        setAllAssets(curr => {
          assets.forEach(asset => {
            const indexOfUpdate = findIndex(
              curr,
              existing => existing.id === asset.id
            );
            curr[indexOfUpdate] = asset; // eslint-disable-line no-param-reassign
          });
        });
      }
    }

    return newAssetsBody;
  };

  const handleError = () => {
    const updatedOrNewAssets = allAssets.filter(
      asset => asset?.new || asset?.editedName
    );

    setAllAssets(curr => {
      updatedOrNewAssets.forEach(asset => {
        if (asset?.new) {
          let parentIndex = -1;
          parentIndex = findIndex(curr, item => {
            return item.id === asset.parent;
          });
          if (parentIndex !== -1) {
            const indexOfChild = findIndex(curr[parentIndex].children, item => {
              return item === asset.id;
            });
            curr[parentIndex].children.splice(indexOfChild, 1);
          }
          const assetIndex = findIndex(curr, item => item.id === asset.id);
          curr.splice(assetIndex); // eslint-disable-line no-param-reassign
        } else {
          const indexOfUpdate = findIndex(
            curr,
            existing => existing.id === asset.id
          );
          curr[indexOfUpdate].name = asset.editedName; // eslint-disable-line no-param-reassign
          delete curr[indexOfUpdate].editedName; // eslint-disable-line no-param-reassign
        }
      });
    });
  };

  return (
    <AssetContext.Provider
      value={{
        getAnotherColumn,
        updateAssetInWizard,
        addAssetToWizard,
        assetWizardColumns,
        assetManipulation,
        setAssetManipulation,
        setAssetWizardColumns,
        index,
        error,
        theAsset,
        setTheAsset,
        setError,
        setIndex,
        setAllAssets,
        allAssets,
        patchAssets,
        postAssets,
        handleError,
        setIsLoading,
        getAssets,
        isLoading,
        wizardOpen,
        setWizardOpen,
        assetsLoading,
        isExpanding,
        setIsExpanding,
        allRefs,
        getAsset,
        deleteError,
        setDeleteError,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};
AssetProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
