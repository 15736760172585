import { Box } from '@mui/material';
import Alert from 'components/alerts';
import AddRevisions from 'components/revisions';
import ParameterTypeSelector from './parameter-types';
import Summary from './summary';
import AddTags from './tags';

export default () => {
  const steps = [
    {
      label: 'Parameters',
      optional: false,
      header: 'Define parameters to add',
      content: props => <ParameterTypeSelector {...props} />,
    },

    {
      label: 'Values',
      optional: true,
      header: 'Add parameter revision',
      content: props => <AddRevisions {...props} />,
    },
    {
      label: 'Metadata',
      optional: true,
      header: 'Select metadata to attach to Parameter(s)',
      content: props => <AddTags {...props} />,
    },
    {
      label: 'Submit',
      optional: false,
      header: 'Are these the Parameters you wish to add?',
      content: ({ submitError, ...props }) => (
        <>
          {submitError && (
            <Box sx={{ padding: theme => theme.spacing(2, 0) }}>
              <Alert
                color="error"
                title={
                  submitError?.msg
                    ? `${submitError?.msg}, please try again`
                    : 'An error occurred, please try again'
                }
                text={
                  submitError?.details
                    ? `${submitError?.details}, please try again or come back at a later time`
                    : 'Please retry to resubmit again, or come back at a later time'
                }
              />
            </Box>
          )}
          <Summary {...props} />
        </>
      ),
    },
  ];

  return steps;
};
